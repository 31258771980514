import {
  Box,
  Button,
  Container,
  Divider,
  HStack,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  Link,
  Avatar
} from '@chakra-ui/react'
import { FaInstagram, FaLinkedin } from 'react-icons/fa'
import React from 'react'
import {Link as GatsbyLink,graphql,useStaticQuery} from 'gatsby'

export const Footer = () => {
  const data = useStaticQuery(graphql`
  {
  allSanityServizi {
    nodes {
      id
      title
      slug {
        current
      }
    }
  }
  allSanityVisite {
    nodes {
      id
      title
      slug {
        current
      }
    }
  }
  sanityLogo {
    mainImage {
      asset {
      gatsbyImageData
    }
  }
    title
    }
  }
  `)

  const links = [{title:"Home",slug:"/"},{title:"Contattami",slug:"/contact-me"},{title:"Chi sono",slug:"/marco-rendine"}]
  return (
  <>
    <Box bg="bg.accent.default" color="fg.accent.default">
    <Container as="footer" role="contentinfo">
      <Stack
        spacing={{
          base: '12',
          md: '8',
        }}
        direction={{
          base: 'column-reverse',
          lg: 'row',
        }}
        py={{
          base: '12',
          md: '16',
        }}
        justify="space-between"
      >
        <SimpleGrid
          columns={{
            base: 2,
            md: 4,
          }}
          gap="8"
          width={{
            base: 'full',
            lg: 'auto',
          }}
        >
        <Stack
          spacing="4"
          minW={{
            lg: '40',
          }}
            >
              {/* <Text fontSize="sm" fontWeight="semibold" color="fg.accent.subtle">
                Navigation
              </Text> */}
              <Stack spacing="3" shouldWrapChildren>
              {links.map((item,i)=>{
                return (
                    <Link key={i} as={GatsbyLink} to={`/blog/${item.slug}`}  _hover={{textDecor: "none"}} >
                      <Button variant="text.accent">
                        {item.title}
                      </Button>
                    </Link>
                )
              })}
              </Stack>
        </Stack>
        <Stack
          spacing="4"
          minW={{
          lg: '40',
          }}
          >
              <Text fontSize="sm" fontWeight="semibold" color="fg.accent.subtle">
                Servizi
              </Text>
              <Stack spacing="3" shouldWrapChildren>
                  <Link as={GatsbyLink} to={`/prima-visita`}  _hover={{textDecor: "none"}} >
                    <Button variant="text.accent">
                      Prima Visita
                    </Button>
                  </Link>
                  <Link as={GatsbyLink} to={`/prima-visita-online`}  _hover={{textDecor: "none"}} >
                    <Button variant="text.accent">
                      Prima Visita Online
                    </Button>
                  </Link>
                  <Link as={GatsbyLink} to={`/percorsi-nutrizionali`}  _hover={{textDecor: "none"}} >
                    <Button variant="text.accent">
                      Percorsi Nutrizionali
                    </Button>
                  </Link>
                  <Link as={GatsbyLink} to={`/visita-di-controllo`}  _hover={{textDecor: "none"}} >
                    <Button variant="text.accent">
                      Visita di Controllo
                    </Button>
                  </Link>
              </Stack>
        </Stack>
        <Stack
          spacing="4"
          minW={{
          lg: '40',
          }}
          >
              <Text fontSize="sm" fontWeight="semibold" color="fg.accent.subtle">
                Di cosa mi occupo
              </Text>
              <Stack spacing="3" shouldWrapChildren>
                <Link as={GatsbyLink} to={`/nutrizione-sportiva`}  _hover={{textDecor: "none"}} >
                    <Button variant="text.accent">
                      Nutrizione Sportiva
                    </Button>
                  </Link>
                  <Link as={GatsbyLink} to={`/nutrizione-clinica`}  _hover={{textDecor: "none"}} >
                    <Button variant="text.accent">
                      Nutrizione Clinica
                    </Button>
                  </Link>
                  <Link as={GatsbyLink} to={`/educazione-alimentare`}  _hover={{textDecor: "none"}} >
                    <Button variant="text.accent">
                      Educazione Alimentare
                    </Button>
                  </Link>
                  <Link as={GatsbyLink} to={`/analisi-della-composizione-corporea`}  _hover={{textDecor: "none"}} >
                    <Button variant="text.accent">
                      Analisi Composizione Corporea
                    </Button>
                  </Link>
              </Stack>
        </Stack>
        </SimpleGrid>
        {/* <Stack spacing="4">
          <Text fontSize="sm" fontWeight="semibold" color="fg.accent.subtle">
            Stay up to date
          </Text>
          <NewsLetter isFooter />
        </Stack> */}
      </Stack>
      <Divider borderColor="bg.accent.subtle" />
      <Stack
        pb="12"
        pt="8"
        justify="space-between"
        direction={{
          base: 'column',
          md: 'row',
        }}
        align={{
          base: 'start',
          md: 'center',
        }}
      >
        <HStack
          justify={{
            base: 'space-between',
            sm: 'start',
          }}
          width={{
            base: 'full',
            sm: 'auto',
          }}
          spacing="8"
        >
          <Avatar name='Logo Marco Rendine' alt="logo" src={data.sanityLogo.mainImage.asset.gatsbyImageData.images.fallback.src} />
          <HStack variant="tertiary.accent">
            <IconButton as="a" href="https://www.linkedin.com/in/marco-rendine-617433262/?originalSubdomain=it" aria-label="LinkedIn" icon={<FaLinkedin />} />
            <Text color="fg.accent.subtle">Marco Rendine</Text>
            <IconButton as="a" href="https://www.instagram.com/Nutrizioneapplicata/" aria-label="Instagram" icon={<FaInstagram />} />
            <Text color="fg.accent.subtle">Nutrizione Applicata</Text>
          </HStack>
        </HStack>
        <Text fontSize="sm" color="fg.accent.subtle">
          &copy; {new Date().getFullYear()} Marco Rendine P.IVA: 13330380968 | Sede Legale: Vicolo Monastero 18 - 20077 Milano
        </Text>
      </Stack>
    </Container>
    </Box>
  </>
  )
}