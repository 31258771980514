import React from "react"
import { theme } from '@chakra-ui/pro-theme'
import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/400-italic.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import {Navbar} from './Navbar/Navbar'
import {Footer} from './Footer/Footer'

const proTheme = extendTheme(theme)
const extenstion = {
  colors: { ...proTheme.colors, brand: proTheme.colors.blue },
    fonts: {
    heading:  `'Montserrat', sans-serif`,
    body: `'Montserrat', sans-serif`,
  },
}
const myTheme = extendTheme(extenstion, proTheme)

export const Layout = ({children}) => {
  return (
     <ChakraProvider theme={myTheme}>
      <Box overflowX="hidden">
        <Navbar />
        {children}
        <Footer />
      </Box>
    </ChakraProvider>
  )
}
