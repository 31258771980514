import { 
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  Heading,
  Avatar,
  // Icon,
  // IconButton,
  // useDisclosure,
  // Input,
  // Text,
  // useMediaQuery,
  // InputGroup,
  // InputLeftElement,
  Link,
  // VStack,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
} from '@chakra-ui/react'
// import { FiSearch } from 'react-icons/fi'
import { DocumentPopover } from './DocumentPopover'
import { MobileDrawer } from './MobileDrawer'
// import {SearchCard} from './SearchCard'
// import axios from "axios"
// import {Link as GatsbyLink,graphql,useStaticQuery} from 'gatsby'
// import React,{ useState } from "react"
import {Link as GatsbyLink,useStaticQuery,graphql} from 'gatsby'
import React from 'react'
import { DocumentPopoverTwo } from './DocumentPopoverTwo'

// const query = graphql`
//   {
//     localSearchBlogs {
//       publicIndexURL
//       publicStoreURL
//     }
//     localSearchCategory {
//       publicIndexURL
//       publicStoreURL
//     }
//   }
// `

export const Navbar = () => {
  // const [isLargerThan768] = useMediaQuery("(min-width: 768px)")
  // const { isOpen, onOpen, onClose } = useDisclosure()
  // const [searchQuery, setSearchQuery] = useState("")
  // const [blogsIndexStore, setBlogsIndexStore] = useState(null)
  // const [categoriesIndexStore, setCategoriesIndexStore] = useState(null)
  // const btnRef = React.useRef()
  // const data = useStaticQuery(query)

  // const {
  //   publicStoreURL: blogsPublicStoreURL,
  //   publicIndexURL: blogsPublicIndexURL,
  // } = data.localSearchBlogs

  // const {
  //   publicStoreURL: categoriesPublicStoreURL,
  //   publicIndexURL: categoriesPublicIndexURL,
  // } = data.localSearchCategory

  // const handleOnFocus = async () => {
  //   if (blogsIndexStore && categoriesIndexStore) return
  //   const [
  //     { data: blogsIndex },
  //     { data: blogsStore },
  //     { data: categoriesIndex },
  //     { data: categoriesStore },
  //   ] = await Promise.all([
  //     axios.get(blogsPublicIndexURL),
  //     axios.get(blogsPublicStoreURL),
  //     axios.get(categoriesPublicIndexURL),
  //     axios.get(categoriesPublicStoreURL),
  //   ])
  //   setBlogsIndexStore({
  //     index: blogsIndex,
  //     store: blogsStore,
  //   })
  //   setCategoriesIndexStore({
  //     index: categoriesIndex,
  //     store: categoriesStore,
  //   })
  // }

  // const setSearchValue = e => {
  //   e.preventDefault()
  //   setSearchQuery(e.target.value)
  // }

  // const resetValue = () => {
  //   setSearchQuery("")
  //   onClose()
  // }
  const navQuery = useStaticQuery(graphql`
  {
    sanityLogo {
      mainImage {
        asset {
        gatsbyImageData
      }
    }
    title
    }
  }
`)
  return (
  <>
  <Box as="section">
    <Box borderBottomWidth="1px" bg="bg.accent.default" position="relative" zIndex="tooltip">
      <Container py="4">
        <HStack justify="space-between" spacing="8">
          <HStack spacing="10">
            <HStack spacing="3">
              <Avatar name='Logo Marco Rendine' alt="logo" src={navQuery.sanityLogo.mainImage.asset.gatsbyImageData.images.fallback.src} />
              <MobileDrawer />
              <Heading as='h3' fontSize='x-large' color='white'>Marco Rendine</Heading>
            </HStack>
            <ButtonGroup
              size="lg"
              variant="text.accent"
              colorScheme="gray"
              spacing="8"
              display={{
                base: 'none',
                lg: 'flex',
              }}
            >
              <Link as={GatsbyLink} _hover={{textDecor: "none"}} to="/">
                <Button>Home</Button>
              </Link>
              <Link as={GatsbyLink} _hover={{textDecor: "none"}} to="/marco-rendine">
                <Button>Chi Sono</Button>
              </Link>
              <DocumentPopover />
              <DocumentPopoverTwo />
              <Link as={GatsbyLink} _hover={{textDecor: "none"}} to="/contact">
                <Button>Contattami</Button>
              </Link>
            </ButtonGroup>
          </HStack>
          {/* <HStack
          
            spacing={{
              base: '2',
              md: '4',
            }}
          >
          <VStack>
          <IconButton
          ref={btnRef}
          onClick={onOpen}
          variant="primary"
          aria-label="Toggle Sidebar"
          icon={<FiSearch fontSize="1.5rem" />}
          />
            </VStack>
          </HStack> */}
        </HStack>
        {/* <Modal size={isLargerThan768 ? "lg" : 'sm'} onClose={onClose} finalFocusRef={btnRef} isOpen={isOpen}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader bg='#2b6cb0'>
              <Text color="#fffffa" as="h4" fontSize="xl" fontWeight="semibold">
                Search
              </Text>
            </ModalHeader>
            <ModalBody bg='#2b6cb0'>
            <InputGroup
              maxW="2xs"
              display={{
                base: 'none',
                md: 'inline-flex',
              }}
            >
              <InputLeftElement>
                <Icon as={FiSearch} color="fg.accent.muted" fontSize="lg" />
              </InputLeftElement>
              <Input           
              value={searchQuery}
              onFocus={handleOnFocus}
              onChange={e => setSearchValue(e)}
              placeholder="Search" 
              variant="filled.accent" 
              />
            </InputGroup>
            {searchQuery && blogsIndexStore && categoriesIndexStore && (
              <SearchCard
                blogsIndexStore={blogsIndexStore}
                searchQuery={searchQuery}
                categoriesIndexStore={categoriesIndexStore}
                onClose={onClose}
                resetValue={resetValue}
              />
            )}
            </ModalBody>
            <ModalFooter bg='#2b6cb0'>
            <Button
            _hover={{backgroundColor:"#3182ce"}}
              size="xl" 
              colorScheme="blue"
              onClick={onClose && resetValue}
            >
              Close
            </Button>
            </ModalFooter>
          </ModalContent>
        </Modal> */}
      </Container>
    </Box>
  </Box>
  </>
  )
}