import React from "react";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
  Link
} from '@chakra-ui/react'
import { PopoverIcon } from './PopoverIcon'
import {Link as GatsbyLink} from 'gatsby'
// import { useStaticQuery, graphql } from "gatsby"

export const DocumentPopover = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  // const data = useStaticQuery(graphql`
  // {
  //   allSanityServizi {
  //     nodes {
  //       title
  //       slug {
  //         current
  //       }
  //       id
  //     }
  //   }
  // }
  // `)
  // const navLinks = data.allSanityServizi.nodes;
  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} trigger="hover" openDelay={0}>
      <PopoverTrigger>
        <Button rightIcon={<PopoverIcon isOpen={isOpen} />}>Servizi</Button>
      </PopoverTrigger>
      <PopoverContent p="2" maxW="fit-content">
        <Stack spacing="0" alignItems="stretch">      
          <Link as={GatsbyLink} to={`/prima-visita`}  _hover={{textDecor: "none"}} >
            <Button  variant="tertiary" justifyContent="start">
              Prima Visita
            </Button>
          </Link>
          <Link as={GatsbyLink} to={`/prima-visita-online`}  _hover={{textDecor: "none"}} >
            <Button  variant="tertiary" justifyContent="start">
              Prima Visita Online
            </Button>
          </Link>
          <Link as={GatsbyLink} to={`/percorsi-nutrizionali`}  _hover={{textDecor: "none"}} >
            <Button  variant="tertiary" justifyContent="start">
              Percorsi Nutrizionali
            </Button>
          </Link>
          <Link as={GatsbyLink} to={`/visita-di-controllo`}  _hover={{textDecor: "none"}} >
            <Button  variant="tertiary" justifyContent="start">
              Visita di Controllo
            </Button>
          </Link>     
        </Stack>
      </PopoverContent>
    </Popover>
  )
}