import { Button, Drawer, DrawerBody, DrawerContent, Stack, useDisclosure,Link } from '@chakra-ui/react'
import { ToggleButton } from './ToggleButton'
import React from 'react'
import { Link as GatsbyLink } from "gatsby"

export const MobileDrawer = () => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  // const data = useStaticQuery(graphql`
  // {
  // allSanityServizi {
  //   nodes {
  //     id
  //     title
  //     slug {
  //       current
  //     }
  //   }
  // }
  // allSanityVisite {
  //   nodes {
  //     id
  //     title
  //     slug {
  //       current
  //     }
  //   }
  // }
  // }
  // `)
  return (
    <>
      <ToggleButton
        isOpen={isOpen}
        onClick={onToggle}
        aria-label="Open menu"
        display={{
          base: 'inline-flex',
          lg: 'none',
        }}
      />
      <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
        <DrawerContent>
          <DrawerBody mt="72px" p="4">
            <Stack spacing="1">
              <Link as={GatsbyLink} to={`/`}  _hover={{textDecor: "none"}} >
                <Button size="lg" variant="tertiary" justifyContent="start">
                  Home
                </Button>
              </Link>
              <Link as={GatsbyLink} to={`/marco-rendine`}  _hover={{textDecor: "none"}} >
                <Button size="lg" variant="tertiary" justifyContent="start">
                  Chi Sono
                </Button>
              </Link>
              <Link as={GatsbyLink} to={`/contact-me`}  _hover={{textDecor: "none"}} >
                <Button size="lg" variant="tertiary" justifyContent="start">
                  Contattami
                </Button>
              </Link>
              <Link as={GatsbyLink} to={`/servizi`}  _hover={{textDecor: "none"}} >
                <Button size="lg" color="black" fontWeight='semibold' variant="tertiary" justifyContent="start">
                  Servizi
                </Button>
              </Link>
              {/* {data.allSanityServizi.nodes.map((item)=>{
                return( 
                <>
                  <Link ml="25px" as={GatsbyLink} key={item.slug.current} to={`/${item.slug.current}`}  _hover={{textDecor: "none"}} >
                    <Button size="lg" variant="tertiary" justifyContent="start">
                    {item.title}
                    </Button>
                  </Link>
                </>
              )})} */}
              
              <Link as={GatsbyLink} to={`/servizi`}  _hover={{textDecor: "none"}} >
                <Button size="lg" color="black" fontWeight='semibold' variant="tertiary" justifyContent="start">
                  Di Cosa Mi Occupo
                </Button>
              </Link>
              {/* {data.allSanityVisite.nodes.map((item)=>{
                return (
                <>
                  <Link ml="25px" as={GatsbyLink} key={item.slug.current} to={`/${item.slug.current}`}  _hover={{textDecor: "none"}} >
                    <Button size="lg" variant="tertiary" justifyContent="start">
                      {item.title}
                    </Button>
                  </Link>
                </>
              )})} */}
            
              {/* <Link as={GatsbyLink} to={`/blog`}  _hover={{textDecor: "none"}} >
                <Button size="lg" variant="tertiary" justifyContent="start">
                  Blog
                </Button>
              </Link>
              {navLinks.map((item)=>{
                return (
                <Link ml="4" key={item.id} as={GatsbyLink} to={`/blog/${item.slug.current}`}  _hover={{textDecor: "none"}} >
                  <Button size="lg" variant="tertiary" justifyContent="start">
                    {item.title}
                  </Button>
                </Link>
                )
              })} */}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}