exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-servizi-jsx": () => import("./../../../src/pages/servizi.jsx" /* webpackChunkName: "component---src-pages-servizi-jsx" */),
  "component---src-pages-visite-jsx": () => import("./../../../src/pages/visite.jsx" /* webpackChunkName: "component---src-pages-visite-jsx" */),
  "component---src-templates-author-jsx": () => import("./../../../src/templates/Author.jsx" /* webpackChunkName: "component---src-templates-author-jsx" */),
  "component---src-templates-servizio-pagina-jsx": () => import("./../../../src/templates/ServizioPagina.jsx" /* webpackChunkName: "component---src-templates-servizio-pagina-jsx" */),
  "component---src-templates-visite-pagina-jsx": () => import("./../../../src/templates/VisitePagina.jsx" /* webpackChunkName: "component---src-templates-visite-pagina-jsx" */)
}

